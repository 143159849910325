var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_vm._t("dialog"),_c('v-card',{staticClass:"content-card-padding"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.exportTransactions,"fields":_vm.exportFields,"meta":[],"name":_vm.excelData.name,"worksheet":_vm.excelData.worksheet}},[_c('app-button',{attrs:{"disabled":!_vm.exportTransactions.length,"secondary":""}},[_vm._v(" Export results ")])],1)],1),_c('v-data-table',{staticClass:"table-container",attrs:{"headers":_vm.headers,"items":_vm.coinsTransactions,"search":_vm.search,"custom-filter":_vm.customFilter,"no-data-text":_vm.noData,"footer-props":{
        itemsPerPageText: 'Items per page:',
        itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
      }},scopedSlots:_vm._u([(_vm.isLoading.coinsTransactions)?{key:"body",fn:function(){return [_c('table-skeleton-loader',{attrs:{"headers":_vm.headers}})]},proxy:true}:{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.date)))]),_c('td',[_vm._v(_vm._s(item.projectTitle))]),_c('td',[_vm._v(_vm._s(item.amount))]),_c('td',[_vm._v(_vm._s(item.projectUnit))]),(_vm.isConversionType)?_c('td',[(item.message)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.getMessage(item.message))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.message))])]):_vm._e()],1):_vm._e(),_c('td',[_vm._v(_vm._s(item.projectCreator))]),_c('td',[_vm._v(_vm._s(item.projectCampaign))]),(_vm.isConversionType)?_c('td',{staticStyle:{"text-align":"right"}},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('showConfirmationPopup', item)}}},[_vm._v(" Undo ")])],1)],1)],1):_vm._e()])]}}],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }