<template>
  <v-container>
    <slot name="dialog" />
    <v-card class="content-card-padding">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <download-excel
          :data="exportTransactions"
          :fields="exportFields"
          :meta="[]"
          :name="excelData.name"
          :worksheet="excelData.worksheet"
        >
          <app-button
            :disabled="!exportTransactions.length"
            secondary
          >
            Export results
          </app-button>
        </download-excel>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="coinsTransactions"
        :search="search"
        :custom-filter="customFilter"
        :no-data-text="noData"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Items per page:',
          itemsPerPageOptions: [12, 24, 36, { text: 'All', value: -1 }]
        }"
      >
        <template
          v-if="isLoading.coinsTransactions"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #item="{ item }"
        >
          <tr>
            <td>{{ item.date | formatDate }}</td>
            <td>{{ item.projectTitle }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.projectUnit }}</td>
            <td v-if="isConversionType">
              <v-tooltip
                v-if="item.message"
                bottom
                max-width="300"
              >
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ getMessage(item.message) }}
                  </span>
                </template>
                <span>{{ item.message }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.projectCreator }}</td>
            <td>{{ item.projectCampaign }}</td>
            <td
              v-if="isConversionType"
              style="text-align: right"
            >
              <v-menu
                bottom
                left
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="$emit('showConfirmationPopup', item)"
                  >
                    Undo
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { TableSkeletonLoader } from '@kickbox/common-admin';
import TRANSACTION_TYPE from '@kickbox/common-util/constants/transaction-type';

export default {
  name: 'BaseCoins',
  components: {
    TableSkeletonLoader
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: 'No items'
    },
    excelData: {
      type: Object,
      default: () => ({
        name: 'Excel.xls',
        worksheet: 'Sheet 1'
      })
    }
  },
  data() {
    return {
      search: ''
    };
  },
  computed: {
    ...mapGetters([
      'coinsTransactions',
      'isLoading',
    ]),
    noData() {
      return this.isLoading.coinsTransactions ? 'loading...' : this.noDataText;
    },
    exportTransactions() {
      return this.coinsTransactions.filter(
        (transaction) => this.customFilter(null, this.search, transaction)
      );
    },
    isConversionType() {
      return this.type === TRANSACTION_TYPE.CONVERT_KICK_COINS;
    },
    exportFields() {
      const fields = {
        Date: { callback: (transaction) => moment(transaction.date).format('MMM DD, YYYY') },
        'Project Title': 'projectTitle',
        Amount: 'amount',
        'Project Unit': 'projectUnit',
      };
      if (this.isConversionType) {
        fields.Message = 'message';
      }
      // keep the header order when export the file
      fields['Project Creator'] = 'projectCreator';
      fields['Project Campaign'] = 'projectCampaign';
      return fields;
    },
    headers() {
      const headers = [
        { text: 'Date', value: 'date', align: 'left', width: 120 },
        { text: 'Project Title', value: 'projectTitle', align: 'left', width: 150 },
        { text: 'Amount', value: 'amount', align: 'left', width: 120 },
        { text: 'Project Unit', value: 'projectUnit', align: 'left', width: 150 },
        { text: 'Project Creator', value: 'projectCreator', align: 'left', width: 150 },
        { text: 'Campaign', value: 'projectCampaign', align: 'left', width: 150 },
      ];
      if (this.isConversionType) {
        headers.splice(4, 0, { text: 'Message', value: 'message', align: 'left', width: 200 });
        headers.splice(7, 0, { sortable: false, width: 30 });
      }
      return headers;
    }
  },
  methods: {
    customFilter(value, search, item) {
      const searchString = search?.toString().toLowerCase() || '';

      if (item.message && item.message.toLowerCase().includes(searchString)) return true;
      if (item.date.toString().toLowerCase().includes(searchString)) return true;
      if (item.projectCreator.toLowerCase().includes(searchString)) return true;
      if (item.amount.toString().toLowerCase().includes(searchString)) return true;
      if (item.projectUnit.toLowerCase().includes(searchString)) return true;
      if (item.projectCampaign.toLowerCase().includes(searchString)) return true;
      return item.projectTitle.toLowerCase().includes(searchString);
    },
    getMessage(message) {
      return message && message.length > 80 ? message.slice(0, 80).trim().concat('...') : message;
    },
  }
};
</script>

<style scoped>
  .list__tile__title {
    text-align: center;
  }
  table.table tbody td:first-of-type {
    width: 125px;
  }
  table.table tbody td:last-of-type {
    padding: 10px;
    text-align: left;
  }
  .list {
    min-width: 80px;
  }
</style>
