<template>
  <base-coins
    :excel-data="{
      name: 'addition-deduction.xls',
      worksheet: 'Addition / Deduction'
    }"
    no-data-text="No addition / deduction coins."
  />
</template>

<script>
import BaseCoins from './BaseCoins';
import transactionService from '@/services/transactionService';

export default {
  name: 'CoinsAdditionDeductionList',
  components: {
    BaseCoins
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (!from.name) {
        transactionService.getProjectCoinsHistory();
      }
    });
  }
};
</script>
