<template>
  <base-coins
    :excel-data="{
      name: 'coin-conversions.xls',
      worksheet: 'Coin Conversions'
    }"
    no-data-text="No coins conversion."
    :type="convertKickCoins"
    @showConfirmationPopup="showConfirmationPopup"
  >
    <confirm-dialog
      v-if="currentItem"
      slot="dialog"
      :show="showConfirmation"
      :confirm-click="handleCoinsConversion"
      @close="closeConfirmationPopup"
    >
      <div>
        <div>Are you sure you want to undo this transaction?</div>
        <div class="subtitle-1 my-2">
          <span>The amount of </span>
          <span style="font-weight: bold">{{ Math.abs(currentItem.amount) }}</span>
          <span> coins will be </span>
          <span v-if="isReverted">returned</span>
          <span v-else>deducted</span>
          <span> to the project budget.</span>
        </div>
      </div>
    </confirm-dialog>
  </base-coins>
</template>

<script>
import { ConfirmDialog } from '@kickbox/common-admin';
import { CONVERT_KICK_COINS } from '@kickbox/common-util/constants/transaction-type';
import BaseCoins from './BaseCoins';
import transactionService from '@/services/transactionService';

export default {
  name: 'CoinsConversionList',
  components: {
    BaseCoins,
    ConfirmDialog
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (!from.name) {
        transactionService.getCoinsConversion();
      }
    });
  },
  data() {
    return {
      currentItem: null,
      showConfirmation: false,
      convertKickCoins: CONVERT_KICK_COINS
    };
  },
  computed: {
    isReverted() {
      return this.currentItem && Math.sign(this.currentItem.amount) === 1;
    }
  },
  methods: {
    async handleCoinsConversion() {
      const transaction = await transactionService
        .handleCoinsConversion(this.currentItem.projectId, this.currentItem.transactionId);
      const newTransaction = {
        projectId: this.currentItem.projectId,
        projectTitle: this.currentItem.projectTitle,
        projectCreator: this.currentItem.projectCreator,
        projectUnit: this.currentItem.projectUnit,
        projectCampaign: this.currentItem.projectCampaign,
        transactionId: transaction.id,
        date: transaction.createdAt,
        amount: transaction.get('amount'),
        message: transaction.get('message'),
      };
      this.$store.commit('addTransaction', newTransaction);
      this.closeConfirmationPopup();
    },
    showConfirmationPopup(item) {
      this.currentItem = item;
      this.showConfirmation = true;
    },
    closeConfirmationPopup() {
      this.showConfirmation = false;
      this.currentItem = null;
    }
  }
};
</script>
